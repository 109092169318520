/* eslint-disable no-undef */
/**
 * Facebook Social Share init
 */
window.fbAsyncInit = function () {
	FB.init({
		appId: '1120901425041789',
		autoLogAppEvents: true,
		xfbml: true,
		version: 'v10.0',
	});
};

jQuery('.fb-share').click(function () {
	FB.ui(
		{
			method: 'share',
			href: window.location.href,
		},
		function (response) {}
	);
});
