/* eslint-disable no-undef */
/**
 * Twitter Social Share init
 */
const Url = window.location.href; // Get current page url
const UrlEncoded = encodeURI(Url);
const title = encodeURIComponent(document.title); // Get page title content
const metaTags = document.getElementsByTagName('meta');
let excerpt = '';
let imageURL = '';
const hashtags = [];
for (const tag of metaTags) {
	if (tag.getAttribute('property') === 'og:description') {
		excerpt = encodeURIComponent(tag.getAttribute('content'));
	}
	if (tag.getAttribute('property') === 'og:image') {
		imageURL = encodeURI(tag.getAttribute('content'));
	}
	if (tag.getAttribute('property') === 'article:tag') {
		hashtags.push(encodeURIComponent(tag.getAttribute('content')));
	}
}
jQuery('.tw-share').each(function () {
	this.setAttribute(
		'onclick',
		`javascript:window.open('https://twitter.com/intent/tweet?text=${title}&url=${UrlEncoded}&hashtags=${
			hashtags.length > 0 ? hashtags.join(',') + ',' : ''
		}GrandCentralMarket', 'targetWindow', 'toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=550,height=280');`
	);
});
