const Url = window.location.href; // Get current page url
const UrlEncoded = encodeURI(Url);
const title = encodeURIComponent(document.title); // Get page title content
const metaTags = document.getElementsByTagName('meta');
let excerpt = '';
let imageURL = '';
const hashtags = [];
for (const tag of metaTags) {
	if (tag.getAttribute('property') === 'og:description') {
		excerpt = encodeURIComponent(tag.getAttribute('content'));
	}
	if (tag.getAttribute('property') === 'og:image') {
		imageURL = encodeURI(tag.getAttribute('content'));
	}
	if (tag.getAttribute('property') === 'article:tag') {
		hashtags.push(encodeURIComponent(tag.getAttribute('content')));
	}
}
/*
document.getElementById('fb-share').href =
	'http://www.facebook.com/share.php?u=' + UrlEncoded;
document.getElementById('tweet').href =
	'https://twitter.com/intent/tweet?url=' +
	UrlEncoded +
	'&via=IXLLearning&text=' +
	title +
	'&hashtags=' +
	hashtags.join(',');
*/
const shareBtn = document.querySelector('.pin-share');
if (!!shareBtn) {
	if (imageURL !== '') {
		shareBtn.setAttribute(
			'onclick',
			`javascript:window.open('https://www.pinterest.com/pin/create/button/?url=${UrlEncoded}&media=${imageURL}&description=${title}', 'targetWindow', 'toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=550,height=280');`
		);
	} else {
		shareBtn.setAttribute(
			'onclick',
			`javascript:window.open('https://www.pinterest.com/pin/create/button/?url=${UrlEncoded}&description=${title}', 'targetWindow', 'toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=550,height=280');`
		);
	}
}
/*
document.getElementById("email-share").href = "mailto:?subject=Check out this post from IXL&body=" + title + "%0D%0A%0D%0A" + excerpt + "%0D%0A%0D%0ASource: " + Url;
*/
